import { useForm } from "react-hook-form";
import { useAppState } from "../../State";
import { TariffTypeList } from "./TariffTypeList";
import { TariffPriceList } from "./TariffPriceList.js";
import { TariffListTotal } from "./TariffListTotal";
import { CheckFormSubmit } from "../buy/CheckFormSubmit";

export const TariffList = ({ tariffList }) => {
    const [state, setState] = useAppState();

    const {
        control,
        handleSubmit,
        watch
    } = useForm({
        defaultValues: {
            tariffs : state.tariffs
        }, mode: "onChange"
    });

    const formWatch = watch();

    const saveData = (data) => {
        setState(prevState => ({ ...prevState, ...data, step: 1 }));
    };

    return (
        <>
            {tariffList
                ?
                <>
                    <div className="price_list-head">
                        <TariffTypeList tariffTypeList={tariffList.tariffTypeList} />
                    </div>
                    <div className="price_list">
                        <form className="price_list-form" onSubmit={handleSubmit(saveData)}>
                            <div className="price_list-body">
                                <TariffPriceList control={control} tariffPriceList={tariffList.tariffPriceList} tariffTypeList={tariffList.tariffTypeList} tariffGroupList={tariffList.tariffGroupList} />
                            </div>
                            <div className="price_list-footer">
                                <TariffListTotal formWatch={formWatch} tariffPriceList={tariffList.tariffPriceList} tariffTypeList={tariffList.tariffTypeList} />
                                <button disabled={CheckFormSubmit('price', formWatch)} className="step-button" type="submit">Продолжить</button>
                            </div>
                        </form>
                    </div>
                </>
                :
                <>
                </>
            }
            <span className="price_list-info">Все цены указаны в Российских рублях</span>
        </>
    )
}
