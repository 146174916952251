import { useEffect, useState } from "react";
import { GFetch } from "../GFetch";
import { ScreenWrapper } from "../components/common"
import { SaleInfoCandeled, SaleInfoCreated, SaleInfoPaid } from "../components/info";

export const Info = ({ orderCode }) => {
    const [saleInfo, setSaleInfo] = useState();
    const [cancelSaleAsk, setCancelSaleAsk] = useState(false);
    const [cancelSaleResult, setCancelSaleResult] = useState();

    useEffect(() => {
        getSaleInfo();
    }, []);

    const cancelSaleHandler = () => {
        GFetch('POST', 'sale/' + orderCode + '/cancel', {})
            .then((res) => {
                setCancelSaleResult(res.success);

                if (res.success) {
                    getSaleInfo();
                }
            })
    }

    const getSaleInfo = () => {
        GFetch('POST', 'sale/' + orderCode, {})
            .then((res) => {
                setSaleInfo(res)
            })
    }

    const saleInfoRenderHandler = (status) => {
        switch (status) {
            case 'created':
                return <SaleInfoCreated saleInfo={saleInfo} />
            case 'canceled':
                return <SaleInfoCandeled saleInfo={saleInfo} />
            case 'paid':
                return <SaleInfoPaid saleInfo={saleInfo} setCancelSaleAsk={setCancelSaleAsk} />
            default:
                return <SaleInfoCreated saleInfo={saleInfo} />
        }
    }
    
    return (
        <ScreenWrapper>
            <div className="sale_info-block">
                {saleInfo &&
                    <>
                        {cancelSaleAsk ?
                            <>
                                <div className="order_cancel-block">
                                    <span className="order_cancel-title">Отмена заказа 🥺</span>
                                    <div className="order_cancel_caption-block">
                                        <span className="order_cancel-text">Вы уверены что хотите отменить заказ {saleInfo.number} ?</span>
                                        <p className="order_cancel-description">
                                            Отменив заказ вы не сможете:<br />
                                            — посетить бассейн на крыше<br />
                                            — побывать на массаже и SPA<br />
                                            — перекусить в отличном ресторане<br />
                                            — поплавать у бара<br />
                                            — побывать на интересных банных церемониях
                                        </p>
                                    </div>
                                    {cancelSaleResult == false &&
                                        <><p>Не удалось</p></>
                                    }
                                    <div className="order_cancel_btn-block">
                                        <span className="order_cancel-btn order_cancel-btn_yes" onClick={() => cancelSaleHandler(false)}>Отменить</span>
                                        <span className="order_cancel-btn order_cancel-btn_no" onClick={() => setCancelSaleAsk(false)}>Я передумал</span>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <span className="sale_info_title">Заказ {saleInfo.number}</span>
                                { saleInfoRenderHandler(saleInfo.status) }
                            </>
                        }
                    </>
                }
            </div>
        </ScreenWrapper>
    )
}