export const SaleInfoCandeled = ({saleInfo}) => {
    return (
        <>
            <div className="order_cancel-block">
                <span className="order_cancel-title">Отмена заказа 🥺</span>
                <div>
                    <span className="order_cancel-text">
                        Заказ {saleInfo.number} успешно отменён!<br></br>
                        Срок возврата средств на карту может составлять до 10 банковских дней.
                    </span>
                </div>
                <div className="order_cancel_btn-block">
                    <a className="order_cancel-btn order_cancel-btn_back" href="/">Вернуться на главную</a>
                </div>
            </div>
        </>
    );
}