import { useAppState } from "../../State"

export const BuyStepNav = () => {
    const [state, setState] = useAppState();
    const listTypes = ["Выбрать дату", "Свободная дата"];
    const steps = ["Тариф", "Контакт", "Оплата"];

    const changeSaleTypeHandler = (saleType) => {
        switch (saleType) {
            case 0:
                setState({ ...state, freeDateSale: 0 });

                break;

            case 1:
                setState({ ...state, freeDateSale: 1, saleDate: state.todayDate })

                break;
        }
    }

    return (
        <>
            <div className='buy_nav'>
                {steps.map((step, index) =>
                    <span
                        key={index}
                        className={index === state.step ? 'active' : ''}>
                        {step}
                    </span>
                )}
            </div>
            {state.step == 0 &&
                <div className="list_type_nav">
                    {listTypes.map((listType, index) =>
                        <button
                            key={index}
                            className={index === state.freeDateSale ? 'active' : ''}
                            onClick={() => changeSaleTypeHandler(index)}
                        >
                            {listType}
                        </button>
                    )}
                </div>
            }
        </>
    )
}
