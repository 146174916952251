import QRCode from "react-qr-code";

export const SaleInfoPaid = ({saleInfo, setCancelSaleAsk}) => {
    const getSaleDate = () => {
        var date = new Date(saleInfo.saleDate);

        var options = {
            month: 'long',
            day: 'numeric',
            weekday: 'short',
        };

        return date.toLocaleDateString('ru', options);
    }

    return (
        <>
            <span className="sale_info_date">Ждём вас в <span>{getSaleDate()}</span></span>
            {saleInfo.tariffs &&
                <>
                    <div className="qr_list">
                        {Object.values(saleInfo.tariffs).map((tariff, index) => {
                            return (
                                <div className="qr_list-item" key={index}>
                                    <span className="qr_list-item-name">{tariff.name}</span>
                                    <QRCode
                                        size={155}
                                        value={tariff.barcode}
                                        viewBox={`0 0 155 155`}
                                    />
                                    <span>
                                        {tariff.barcode}
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </>
            }
            <div className="sale_info_faq-block">
                <ul className="sale_info_faq-list">
                    <li><span>🚘</span> Приехать к нам в комплекс по адресу Свердловский 51а</li>
                    <li><span>✏️</span> Заполнить анкету перед кассой или <a href="https://anketa.letotermy.ru/">онлайн</a></li>
                    <li><span>🎟</span> Пройти с электронным билетом и анкетой на кассу</li>
                    <li><span>☀️</span> Наслаждаться посещением</li>
                </ul>
            </div>
            <span className="sale_info_phone">Телефон: <b><a href="tel:83512400024">8 (351) 24-000-24</a></b></span>
            {
                saleInfo.customer.email &&
                <div className="sale_info_email">Для продления парковки, используйте QR-код из чека “Платформа ОФД”, отправленный на почту <b>{saleInfo.customer.email}</b></div>
            }
            <span className="sale_info_helper sale_info_helper_paid">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.25 9.25L9.29149 9.22926C9.86461 8.9427 10.5099 9.46034 10.3545 10.082L9.64549 12.918C9.49009 13.5397 10.1354 14.0573 10.7085 13.7707L10.75 13.75M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10ZM10 6.25H10.0075V6.2575H10V6.25Z" stroke="#112867" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span>
                    Проход осуществляется в порядке живой очереди.
                </span>
            </span>
            <span className="order_cancel_action" onClick={() => setCancelSaleAsk(true)}>Отменить заказ</span>
        </>
    );
}