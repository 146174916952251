export const SaleInfoCreated = () => {
    return (
        <>
            <span className="sale_info_helper sale_info_helper_created">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2L12 5" stroke="#9C510B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12 19L12 22" stroke="#9C510B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M22 12L19 12" stroke="#9C510B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M5 12L2 12" stroke="#9C510B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M19.071 4.92897L16.9497 7.05029" stroke="#9C510B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.05028 16.95L4.92896 19.0713" stroke="#9C510B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4.92897 4.92897L7.05029 7.05029" stroke="#9C510B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M16.9497 16.95L19.071 19.0713" stroke="#9C510B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span>
                    Проверка оплаты
                </span>
            </span>
        </>
    );
}